<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Fee Heads
            <v-spacer></v-spacer>
            <add-button
              permission="fee-heads-read"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              >New Fee Head</add-button
            >
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4>
                <v-text-field
                  outlined
                  dense
                  hint="Atleast 3 characters"
                  flat
                  color="primary"
                  class="pa-0"
                  label="Search By Name"
                  v-model="searchFH"
                  @input="searchFeeHead"
                  append-icon="search"
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="form.items.data"
            :search="search"
            sort-by="title"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.title }}
                  <div>
                    <span style="font-size: 10px; color: #666">
                      {{ item.inventory_item ? item.inventory_item.name : '' }}
                    </span>
                  </div>
                </td>
                <td class="text-xs-left">
                  {{ item.name || '-' }}
                </td>
                <td class="text-xs-left">{{ item.description }}</td>
                <td
                  class="text-xs-right"
                  v-if="
                    item.title.toLowerCase() == 'advance' ||
                    item.title.toLowerCase() == 'previous due' ||
                    item.title.toLowerCase() == 'penalty' ||
                    item.title.toLowerCase() == 'transport' ||
                    item.title.toLowerCase() == 'cheque' ||
                    item.title.toLowerCase() == 'esewa' ||
                    item.title.toLowerCase() == 'khalti' ||
                    item.title.toLowerCase() == 'connectips' ||
                    item.title.toLowerCase() == 'imepay' ||
                    item.title.toLowerCase() == 'security deposit'
                  "
                >
                  -
                </td>
                <td class="text-right" v-else>
                  <view-button
                    @action="
                      $router.push({
                        name: 'customized-fee',
                        query: { title: item.title, fee_head_id: item.id },
                      })
                    "
                    v-if="item.allow_customization"
                    permission="fee-heads-update"
                    :label="'Customize'"
                  />
                  <edit-button
                    permission="fee-heads-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="fee-heads-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>

          <!--<v-card-actions>-->
          <!--<v-spacer/>-->
          <!--<v-pagination v-if="form.items.data.length > 0" v-model="pagination.page"-->
          <!--:length="form.items.meta.last_page"></v-pagination>-->
          <!--<v-spacer/>-->
          <!--</v-card-actions>-->
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <br />
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-text-field
                autocomplete="off"
                label="Fee Head Name*"
                required
                class="pa-0 pt-3"
                v-model="form.title"
                name="title"
                :error-messages="form.errors.get('title')"
                outlined
                dense
                :rules="[(v) => !!v || 'Title Field is required']"
              />
              <v-flex xs12>
                <v-textarea
                  outlined
                  dense
                  name="description"
                  label="Description"
                  v-model="form.description"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  outlined
                  dense
                  name="Items"
                  label="Select Inventory Item"
                  :items="inventoryItems"
                  v-model="form.inventory_item_id"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  outlined
                  dense
                  name="Items"
                  label="Select Eca Head"
                  :items="ecaHeads"
                  v-model="form.eca_head_id"
                  clearable
                  @click:clear="cleared"
                />
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  :disabled="form.item_id"
                  style="margin-right: 20px"
                  color="blue"
                  v-model="form.allow_customization"
                >
                  <template v-slot:label>
                    <div style="color: #333">
                      Allow Cutomization?
                      <p style="line-height: 15px">
                        <small style="color: #999; font-size: 11px">
                          By allowing customization you can create fee
                          definitions for this individual fee head. Fee
                          Definitions that are created will automatically be
                          included in the bill generation.
                        </small>
                      </p>
                    </div>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  :disabled="form.item_id"
                  style="margin-right: 20px"
                  color="blue"
                  v-model="form.allow_individual"
                >
                  <template v-slot:label>
                    <div style="color: #333">
                      Allow Individual?
                      <p style="line-height: 15px">
                        <small style="color: #999; font-size: 11px">
                          Allowed Fee Heads can be setup for individual student invoice generation
                        </small>
                      </p>
                    </div>
                  </template>
                </v-checkbox>
              </v-flex>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              outlined
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" outlined @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import Mixins from '@/library/Mixins';
import ViewButton from '../../../../components/ViewButton.vue';

export default {
  components: { ViewButton },
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    form: new Form(
      {
        title: '',
        description: '',
        allow_customization: '',
        inventory_item_id: '',
        eca_head_id:'',
        allow_individual:'',
      },
      '/api/fee-head'
    ),
    search: null,
    headers: [
      { text: '#', align: 'center', value: 'id', width: 50, sortable: false },
      { text: 'Title', align: 'left', value: 'title' },
      { text: 'Eca', align: 'left', value: 'name' },
      {
        text: 'Description',
        align: 'left',
        value: 'description',
        sortable: false,
      },
      { text: 'Action', align: 'right', sortable: false, width: 350 },
    ],
    pagination: {
      itemsPerPage: 15,
    },
    inventoryItems: [],
    searchFH: '',
    ecaHeads: [],
  }),

  computed: {
    ...mapState(['batch']),
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
  },
  mounted() {
    this.getItem();
    this.getEcaHead();
    this.get();

  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    searchFeeHead: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString(`withDefaultHeads=true&search=${this.searchFH}`)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    save() {
      this.form.batch_id = this.batch.id;
      this.form.store().catch((e) => {
        this.$events.fire('notification', {
          message: e.data.message,
          status: 'error',
        });
      });
    },

    setChart(data) {
      if (data == null) {
        data = this.form.items.data;
      }

      this.chartOptions.labels = data.map((item) => {
        return item.name;
      });

      this.chartData = data.map((item) => {
        return Math.floor(Math.random() * Math.floor(50));
      });
    },

    viewGrade(id) {
      this.$router.push({
        name: 'grades-detail',
        params: {
          gradeId: id,
        },
      });
    },

    test() {},

    getItem() {
      this.$rest
        .get('/api/account/inventory/item?rowsPerPage=50')
        .then(({ data }) => {
          data.data.map((item) => {
            this.inventoryItems.push({ value: item.id, text: item.name });
          });
        });
    },
    getEcaHead(){
      this.$rest.get('/api/billing-eca-head?rowsPerPage=50')
      .then(({ data })=>{
        data.data.map((item) => {
            this.ecaHeads.push({ value: item.id, text: item.name });
          });
      })
    },
    cleared(){
      this.form.eca_head_id = ""
    }
  },
};
</script>
<style lang="scss">
/*.page-layout {*/

/*}*/
</style>
